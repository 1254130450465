@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  padding-left: 0px!important;
  padding-right: 0px!important;
}

body {
  background-color: #f5f5f5;
}

a {
  color: inherit;
  text-decoration: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  user-select: none !important;
}

.MuiDialogActions-root,.MuiDialogTitle-root{
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.MuiDialogTitle-root{
  padding-top: 15px !important;
  padding-bottom:  0px !important;
  padding-left: 30px !important;
  padding-right: 24px !important;
}

@keyframes entryX {
  from {
    transform: translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes entryY {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
